@each $colorClass, $color in $theme-colors {
	.text-#{$colorClass}-hvr-light {
		transition: .1s color ease-out;

		&:hover {
			color: darken($color, 3%) !important;
		}
	}

	.text-#{$colorClass}-hvr {
		transition: .1s color ease-out;

		&:hover {
			color: darken($color, 6%) !important;
		}
	}

	.text-#{$colorClass}-hvr-dark {
		transition: .1s color ease-out;

		&:hover {
			color: darken($color, 10%) !important;
		}
	}

	.bg-#{$colorClass}-hvr-light {
		transition: .1s background-color ease-out;

		&:hover {
			background-color: darken($color, 2.5%) !important;
		}
	}

	.bg-#{$colorClass}-hvr {
		transition: .1s background-color ease-out;

		&:hover {
			background-color: darken($color, 5%) !important;
		}
	}

	.bg-#{$colorClass}-hvr-dark {
		transition: .1s background-color ease-out;

		&:hover {
			background-color: darken($color, 8%) !important;
		}
	}
}
