$margins: (
	0: .15rem !important,
	1: .375rem !important,
	2: .75rem !important,
	3: 1.25rem !important,
	4: 2rem !important,
);

@each $size, $value in $margins {
	.m-#{$size}\.5 {
		margin: $value;
	}

	.my-#{$size}\.5 {
		margin-top: $value;
		margin-bottom: $value;
	}

	.mx-#{$size}\.5 {
		margin-left: $value;
		margin-right: $value;
	}

	.mt-#{$size}\.5 {
		margin-top: $value;
	}

	.mb-#{$size}\.5 {
		margin-bottom: $value;
	}

	.ml-#{$size}\.5 {
		margin-left: $value;
	}

	.mr-#{$size}\.5 {
		margin-right: $value;
	}

	@each $screen, $screenPx in $grid-breakpoints {
		@media (min-width: $screenPx) {
			.m-#{$screen}-#{$size}\.5 {
				margin: $value;
			}

			.my-#{$screen}-#{$size}\.5 {
				margin-top: $value;
				margin-bottom: $value;
			}

			.mx-#{$screen}-#{$size}\.5 {
				margin-left: $value;
				margin-right: $value;
			}

			.mt-#{$screen}-#{$size}\.5 {
				margin-top: $value;
			}

			.mb-#{$screen}-#{$size}\.5 {
				margin-bottom: $value;
			}

			.ml-#{$screen}-#{$size}\.5 {
				margin-left: $value;
			}

			.mr-#{$screen}-#{$size}\.5 {
				margin-right: $value;
			}
		}
	}
}

.m-4\.75 {
	margin: 2.5rem !important;
}

.my-4\.75 {
	margin-top: 2.5rem !important;
	margin-bottom: 2.5rem !important;
}

.mx-4\.75 {
	margin-left: 2.5rem !important;
	margin-right: 2.5rem !important;
}

.mt-4\.75 {
	margin-top: 2.5rem !important;
}

.mb-4\.75 {
	margin-bottom: 2.5rem !important;
}

.ml-4\.75 {
	margin-left: 2.5rem !important;
}

.mr-4\.75 {
	margin-right: 2.5rem !important;
}

@each $screen, $screenPx in $grid-breakpoints {
	@media (min-width: $screenPx) {
		.m-#{$screen}-4\.75 {
			margin: 2.5rem !important;
		}

		.my-#{$screen}-4\.75 {
			margin-top: 2.5rem !important;
			margin-bottom: 2.5rem !important;
		}

		.mx-#{$screen}-4\.75 {
			margin-left: 2.5rem !important;
			margin-right: 2.5rem !important;
		}

		.mt-#{$screen}-4\.75 {
			margin-top: 2.5rem !important;
		}

		.mb-#{$screen}-4\.75 {
			margin-bottom: 2.5rem !important;
		}

		.ml-#{$screen}-4\.75 {
			margin-left: 2.5rem !important;
		}

		.mr-#{$screen}-4\.75 {
			margin-right: 2.5rem !important;
		}
	}
}
