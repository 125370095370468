$paddings: (
	0: .15rem !important,
	1: .375rem !important,
	2: .75rem !important,
	3: 1.25rem !important,
	4: 2rem !important,
);

@each $size, $value in $paddings {
	.p-#{$size}\.5 {
		padding: $value;
	}

	.py-#{$size}\.5 {
		padding-top: $value;
		padding-bottom: $value;
	}

	.px-#{$size}\.5 {
		padding-left: $value;
		padding-right: $value;
	}

	.pt-#{$size}\.5 {
		padding-top: $value;
	}

	.pb-#{$size}\.5 {
		padding-bottom: $value;
	}

	.pl-#{$size}\.5 {
		padding-left: $value;
	}

	.pr-#{$size}\.5 {
		padding-right: $value;
	}

	@each $screen, $screenPx in $grid-breakpoints {
		@media (min-width: $screenPx) {
			.p-#{$screen}-#{$size}\.5 {
				padding: $value;
			}

			.py-#{$screen}-#{$size}\.5 {
				padding-top: $value;
				padding-bottom: $value;
			}

			.px-#{$screen}-#{$size}\.5 {
				padding-left: $value;
				padding-right: $value;
			}

			.pt-#{$screen}-#{$size}\.5 {
				padding-top: $value;
			}

			.pb-#{$screen}-#{$size}\.5 {
				padding-bottom: $value;
			}

			.pl-#{$screen}-#{$size}\.5 {
				padding-left: $value;
			}

			.pr-#{$screen}-#{$size}\.5 {
				padding-right: $value;
			}
		}
	}
}

.p-4\.75 {
	padding: 2.5rem !important;
}

.py-4\.75 {
	padding-top: 2.5rem !important;
	padding-bottom: 2.5rem !important;
}

.px-4\.75 {
	padding-left: 2.5rem !important;
	padding-right: 2.5rem !important;
}

.pt-4\.75 {
	padding-top: 2.5rem !important;
}

.pb-4\.75 {
	padding-bottom: 2.5rem !important;
}

.pl-4\.75 {
	padding-left: 2.5rem !important;
}

.pr-4\.75 {
	padding-right: 2.5rem !important;
}

@each $screen, $screenPx in $grid-breakpoints {
	@media (min-width: $screenPx) {
		.p-#{$screen}-4\.75 {
			padding: 2.5rem !important;
		}

		.py-#{$screen}-4\.75 {
			padding-top: 2.5rem !important;
			padding-bottom: 2.5rem !important;
		}

		.px-#{$screen}-4\.75 {
			padding-left: 2.5rem !important;
			padding-right: 2.5rem !important;
		}

		.pt-#{$screen}-4\.75 {
			padding-top: 2.5rem !important;
		}

		.pb-#{$screen}-4\.75 {
			padding-bottom: 2.5rem !important;
		}

		.pl-#{$screen}-4\.75 {
			padding-left: 2.5rem !important;
		}

		.pr-#{$screen}-4\.75 {
			padding-right: 2.5rem !important;
		}
	}
}
