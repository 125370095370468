.FooterComponent {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex: none;
      justify-content: center;
      width: 100%;
      margin-bottom: 24px;
    }

    .brand {
      img {
        display: block;
        height: 32px;
      }
    }

    .social,
    .links {
      a {
        color: inherit;
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }

    .social {
      align-items: flex-end;
    }

    .copyright {
      font-size: 0.9rem;
      opacity: 0.6;

      a {
        color: inherit;
        margin-left: 0.8rem;
      }
    }

    // Tablet and up
    @media screen and (min-width: 768px) {
      > div {
        flex: 50%;
      }

      .left {
        justify-content: flex-start;
      }

      .right {
        justify-content: flex-end;
      }

      // Move links to end (bottom right)
      // Swaps position with social
      .links {
        order: 1;
      }
    }
  }
}
