.NavbarCustom {
	.container {
		.navbar-brand {
			display: flex;
			align-items: center;

			.logo {
				height: 30px;

				@media only screen and (max-width : 575px) {
					height: 20px;
				}
			}
		}
	}
}
