@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #5e72e4,
  "primary-dark": darken(#5e72e4, 10%),
  "secondary": #6c757d,
  "success": #2dce89,
  "success-dark": #29b579,
  "danger": #f5365c,
  "danger-dark": #c62828,
  "warning": #fb6340,
  "warning-light": #f57f17,
  "info": #11cdef,
  "info-dark": darken(#11cdef, 5%),
  "light": #f4f5f7,
  "dark": #172b4d,
  "white": #ffffff,
  "transparent": transparent,
  "gray-100": #f6f9fc,
  "gray-200": #e9ecef,
  "gray-300": #dee2e6,
  "gray-400": #ced4da,
  "gray-500": #adb5bd,
  "gray-600": #8898aa,
  "gray-700": #525f7f,
  "gray-800": #32325d,
  "gray-900": #212529,
);

// TEXT
$font-family-sans-serif: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

// Custom
@import "./custom";

#root {
  background-color: $gray-100;

  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }

  .pointer {
    cursor: pointer;
  }

  .w-fit {
    width: fit-content;
  }
}

html > body > iframe { display: none; }

.noscroll {
  overflow: hidden;
}
